export const seo = {
  url: 'jobs',
  title: {
    en: 'Job offers - transport, forwarding, logistics',
    es: 'Ofertas de trabajo - transporte, envío, logística',
    ro: 'Oferte de muncă - transport, expediere, logistică',
  },
  desc: {
    en: 'Find out about the latest job offers in the TFL industry. Find a branch in your city and join the most recognizable logistics company in Poland!',
    es: 'Infórmese de las últimas ofertas de trabajo en la industria TFL. ¡Encuentre una sucursal en su ciudad y únase a la empresa de logística más reconocida de Polonia!',
    ro: 'Aflați despre cele mai recente oferte de muncă din industria TFL. Găsiți o sucursală în orașul dvs. și alăturați-vă celei mai cunoscute companii de logistică din Polonia!',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'jobs',
    'offers',
    'career',
    'forwarder',
  ],
}

export const intro = {
  title: {
    en: 'Job Offers in TFL',
    es: 'Ofertas de empleo TFL',
    ro: 'Oferte de muncă în TFL',
  },
  desc: {
    en: 'Find out about the latest job offers in the TFL industry. Join the most recognizable logistics company in Poland!',
    es: 'Infórmese de las últimas ofertas de trabajo en la industria TFL. ¡Únase a la empresa de logística más reconocida de Polonia!',
    ro: 'Aflați despre cele mai recente oferte de muncă din industria TFL. Alăturați-vă celei mai cunoscute companii de logistică din Polonia!',
  },
  button: {
    text: {
      en: 'Explore',
      es: 'Explorar',
      ro: 'Explorare',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'Job offers in logistics. Become a forwarder!',
        es: 'Ofertas de empleo en logística. ¡Conviértete en un expeditor!',
        ro: 'Oferte de muncă în logistică. Deveniți expeditor!',
      },
      texts: [
        {
          en: '<span>We are a large recognizable transport company. We offer <strong>stable cooperation conditions</strong>, <strong>industry training</strong> and work in one of the <strong>fastest growing</strong> Polish transport companies. Become part of the Omida Logistics team. Please contact us.</span>',
          es: '<span> Somos una gran empresa de transporte reconocible. Ofrecemos <strong> condiciones de cooperación estables </strong>, <strong> formación industrial </strong> y trabajo en una de las <strong> empresas de transporte de más rápido crecimiento </strong> de Polonia. Forma parte del equipo de Logística de Omida. Póngase en contacto con nosotros. </span>',
          ro: '<span>Suntem o mare companie de transport recunoscută. Oferim <strong>condiții stabile de cooperare</strong>, <strong>formare în industrie</strong> și lucrăm într-una dintre companiile de transport poloneze cu <strong>cea mai rapidă creștere</strong>. Faceți parte din echipa Omida Logistics. Vă rugăm să ne contactați.</span>',
        },
      ],
    },
    {
      headline: {
        en: 'Would you like to open a forwarding branch in your city?',
        es: '¿Quieres abrir una sucursal de envíos en tu ciudad?',
        ro: 'Ați dori să deschideți o sucursală de expediere în orașul dumneavoastră?',
      },
      texts: [
        {
          en: `Contact us and let's open a branch in your town together. We are in large cities and smaller towns. Check out the existing TFL branches below.`,
          es: 'Contáctanos y abramos juntos una sucursal en tu localidad. Estamos en ciudades grandes y pueblos más pequeños. Consulte las sucursales de TFL existentes a continuación.',
          ro: 'Contactează-ne și hai să deschidem împreună o sucursală în orașul tău. Suntem în orașe mari și orașe mai mici. Consultați mai jos sucursalele existente ale TFL.',
        },
      ],
    },
  ],
}
